<template>
  <div v-loading="loading" class="camping-container">
    <!-- 活动背景图 -->
    <div class="bg-img">
      <img src="~@/assets/image/campingPic/campic.png" alt="">
    </div>

    <div class="camping-container-wrapper">
      <div class="active-header-container">
        <div class="block">
          <img src="~@/assets/image/campingPic/campic.png" alt="">
        </div>
        <div class="bg-title-container">
          <p class="page-active-title">
            <img src="~@/assets/image/campingPic/page-active-title.png" alt="">
          </p>
          <p class="page-active-sub-title">一面是日光 一面是果香</p>

          <!-- 标题导航 -->
          <div class="page-active-nav">
            <div class="page-active-nav-wrapper" v-once>
              <div v-for="(i, j) in activeNavList" class="nav-item" :key="j" @click="handleViewGroup(i.key)">
                <img :src="i.url" alt="">
                <img class="title-img" :src="i.titleUrl" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-title music">
        <img
          src="@/assets/image/campingPic/17@2x.png"
          alt=""
        />
        <div class="anchor" ref="ViewGroupMusic" />
      </div>
      <div class="musicInformation">
        <div class="musicInformationBox">
          <el-table :data="musicDataList" style="width: 100%" @row-click="handlePlayRowMusic">
            <el-table-column prop="date" width="410px">
              <template slot-scope="scope">
                <div class="tableBox">
                  <div class="table_h">
                    <img
                      :src="scope.row.logo"
                      alt=""
                    />
                  </div>
                  <div class="table_t">
                    <p>{{ scope.row.name }}</p>
                    <p>BPM：{{ scope.row.bpm }}</p>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="duration" width="330px">
              <template slot-scope="scope">
                <span v-for="(item, index) in scope.row.label" :key="index">
                  {{ item.name.slice(0, 5) }}
                  <span style="margin-right: 10px"></span>
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="" label="" width="214px">
              <template slot-scope="scope">
                {{ scope.row.duration | formatTimeBySecond }}
              </template>
            </el-table-column>
            <el-table-column label="" width="auto">
              <template slot-scope="{ row: info, row: { is_collect, object_id, name }}">
                <div class="operation">
                  <div class="icon-button-container">
                    <!-- 处理音乐收藏 -->
                    <img
                      :src="require(`@/assets/image/campingPic/${is_collect === 1 ? '@2x (1)' : '@2x'}.png`)"
                      @click.stop.prevent="handleMusicCollect(info)"
                      style="width: 20px; height: 20px"
                    />
                    <!-- 音乐下载 -->
                    <img
                      :src="require(`@/assets/image/campingPic/@3x.png`)"
                      @click.stop.prevent="handleDownload(info, 'single')"
                    />
                    <!-- 音乐分享 -->
                    <img
                      :src="require(`@/assets/image/campingPic/@4x.png`)"
                      @click.stop.prevent="handleShare('',object_id, 2, name)"
                    />
                  </div>
                  <el-button
                    class="btn"
                    size="mini"
                    @click.stop.prevent="
                      handleAddGoodsToCart({
                        object_id: object_id,
                        type: 2,
                        name: name
                      })
                    "
                  >立即加购
                  </el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="section-title video">
        <img
          src="@/assets/image/campingPic/18@2x.png"
        />
        <div class="anchor" ref="ViewGroupVideo" />
      </div>
      <div class="videos">
        <div class="videos_list">
          <div
            class="videos_list_left"
            @click.stop.prevent="gotoVideoDetail(videoForLeft.object_id)"
          >
            <VideoCardForActive
              v-if="videoForLeft"
              :video-url="videoForLeft.watermark_file"
              :title="videoForLeft.name"
              :large="true"
              :info="videoForLeft"
              :collected.sync="videoForLeft.is_collected"
              :detail="videoForLeft.label.map(i => i.name).join(',')"
              :carted.sync="videoForLeft.is_cart"
              @change="handleChangeInfo"
            />
          </div>
          <div class="videos_list_rigth">
            <div
              class="videos_list_rigth_box"
              v-for="(item, index) in videoForRightList"
              :key="index"
              @click.stop.prevent="gotoVideoDetail(item.object_id)"
            >
              <VideoCardForActive
                :video-url="item.watermark_file"
                :title="item.name"
                :info="item"
                :detail="item.label.map(i => i.name).join(',')"
                :collected.sync="item.is_collected"
                :carted.sync="item.is_cart"
                @change="handleChangeInfo"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="section-title effect">
        <img
          src="@/assets/image/campingPic/19@2x.png"
        />
        <div class="anchor" ref="ViewGroupEffect" />
      </div>
      <div class="effect-container">
        <div class="sound-effect-list-content">
          <effectCard
            v-for="(item, index) in list"
            :key="index"
            :data="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import effectCard from "@/components/SoundEffectCard";
import { ActivityCampingData } from "@/api/activity";
import Buttons from "@/layout/mixins/Buttons";
import VideoCardForActive from "@/views/activity/camping/components/VideoCardForActive";

export default {
  components: { VideoCardForActive, effectCard },
  data() {
    return {
      activeNavList: [
        { title: "露营 · 音乐", url: require("@/assets/image/campingPic/10@2x.png"), titleUrl: require("@/assets/image/campingPic/active-music-nav-title.png.png"), key: "Music" },
        { title: "露营 · 视频", url: require("@/assets/image/campingPic/12@2x.png"), titleUrl: require("@/assets/image/campingPic/active-video-nav-title.png"), key: "Video" },
        { title: "露营 · 音效", url: require("@/assets/image/campingPic/20@2x.png"), titleUrl: require("@/assets/image/campingPic/active-effect-nav-title.png.png"), key: "Effect" }
      ],
      loading: false,
      list: [],
      musicDataList: [],
      videoDataList: []
    };
  },
  computed: {
    videoForLeft() {
      return this.videoDataList[0];
    },
    videoForRightList() {
      const list = [...this.videoDataList];
      return list.splice(1, 4);
    }
  },
  mixins: [Buttons],
  mounted() {
    this.initialization();
  },
  methods: {
    handlePlayRowMusic(row) {
      this.handlePlayerMusic(row)
    },
    /**
     * 跳转到视频详情页
     * @param {String} videoId 视频id
     */
    async gotoVideoDetail(videoId) {
      // 添加限制播放次数拦截
      const result = await this.handleCheckedTimes(4, videoId);

      if (!result) return;
      this.$router.push(`/video/detail?videoId=${videoId}`);
    },

    handleViewGroup(index) {
      this.$refs[`ViewGroup${index}`]?.scrollIntoView({ block: "start", behavior: "smooth" });
    },

    async initialization() {
      this.loading = true;
      let data = await ActivityCampingData({ number: 1 });
      //初始化接口
      this.initializationList = data.data.list;
      //音乐数据
      this.musicDataList = data.data.list[2];
      //视频数据
      this.videoDataList = data.data.list[4].map(i => {
        return {
          ...i,
          is_collected: i.is_collect
        }
      });
      //音频数据
      this.list = data.data.list[3];
      this.loading = false;
    },

    /**
     * 信息改变
     * @param info
     * @returns {Promise<void>}
     */
    async handleChangeInfo(info) {
      const index = this.videoDataList.findIndex(i => i.object_id === info.object_id);
      console.log(JSON.stringify(info));
      if (index > -1) {
        this.$set(this.videoDataList, index, info);
      }
    },

    /**
     * 视频收藏
     * @param info
     * @returns {Promise<void>}
     */
    async handleVideoCollect(info) {
      await this.handleCollect(info.object_id, 4, info, false);
      this.$emit("change", info);
      this.$emit("update:collected", this.collected === 1 ? 0 : 1);
    },
    //收藏点击
    /** 处理音乐收藏问题
     * 2021-12-30 11:57:59
     * @author SnowRock
     * @param item
     * @returns {Promise<void>}
     */
    async handleMusicCollect(item) {
      await this.handleCollect(
        item.object_id,
        2,
        {
          ...item,
          type: 2,
          labels: item.label
        },
        false
      );
      item.is_collect = item.is_collect === 0 ? 1 : 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.camping-container {
  min-height: calc(100vh - 200px);
  padding-top: 70px;
  padding-bottom: 70px;
  position: relative;

  .camping-container-wrapper {
    position: relative;
    z-index: 4;
  }

  .section-title {
    text-align: center;
    margin-top: 3px;
    position: relative;

    img {
      width: 183px;
    }

    &.music {
      margin-top: 13px;
      margin-bottom: 24px;

      img {
      }
    }

    &.video {
      margin-top: 58px;
      margin-bottom: 36px;
    }

    &.effect {
      margin-top: 70px;
      margin-bottom: 36px;
    }
  }

  /deep/ .cell {
    width: 273px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .musicInformation {
    width: 100%;
    display: flex;
    justify-content: center;

    .musicInformationBox {
      width: 1200px;
      background-color: transparent;

      /deep/ {
        .el-table__header-wrapper {
          display: none;
        }

        .el-table {
          background-color: transparent;

          &__row {
            cursor: pointer;
            border-radius: 4px;
            overflow: hidden;
          }

          &:before {
            display: none;
          }

          td.el-table__cell {
            border-bottom: none;
            padding-top: 12px;
            padding-bottom: 12px;

            &:first-child {
              border-radius: 2px 0 0 2px;
            }

            &:last-child {
              border-radius: 0 2px 2px 0;
            }

            .cell {
              padding-left: 12px;
            }
          }
        }
      }
    }
  }

  .btn {
    width: 88px;
    height: 32px;
    color: #E52A0D;
    border: 1px solid #E52A0D;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }

  .btn:hover {
    background: #E52A0D;
    color: #ffffff;
    border: 1px solid #E52A0D;
  }

  .tableBox {
    display: flex;
    align-items: center;

    .table_h {
      height: 70px;

      img {
        width: 70px;
        height: 70px;
        border-radius: 2px;
      }
    }

    .table_t {
      margin-left: 12px;

      p:nth-child(1) {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        line-height: 20px;
        margin-bottom: 6px;
      }

      p:nth-child(2) {
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        line-height: 17px;
      }
    }
  }

  .operation {
    display: flex;
    align-items: center;

    .icon-button-container {
      display: flex;
      margin-right: 36px;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        cursor: pointer;

        & + img {
          margin-left: 16px;
        }
      }
    }
  }

  .effect-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
  }

  .videos {
    width: 100%;
    display: flex;
    justify-content: center;

    .videos_list {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .videos_list_left {
        width: 594px;
        height: 342px;
        position: relative;
        cursor: pointer;

        .videos_list_left_ICON {
          width: 240px;
          position: absolute;
          bottom: 8px;
          right: 12px;
          display: flex;
          justify-content: space-between;

          & > li {
            width: 42px;
            height: 42px;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 4px;
            // backdrop-filter: blur(1px);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .promptText {
          position: absolute;
          left: 32px;
          top: 24px;

          & > p:nth-child(1) {
            font-size: 16px;
            font-weight: 500;
            color: #ffffff;
            line-height: 22px;
          }

          & > p:nth-child(2) {
            font-size: 12px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.75);
            line-height: 12px;
          }
        }
      }

      .videos_list_rigth {
        width: 584px;
        height: 342px;

        & > div {
          width: calc(50% - 10px);
          position: relative;
          float: left;
          margin-bottom: 26px;
          cursor: pointer;

          & + div {
            margin-left: 20px;
          }

          &:nth-of-type(2n + 1) {
            margin-left: 0;
          }
        }

        .videos_list_rigth_box:hover .videos_list_left_ICON {
          z-index: 1;
        }

        .videos_list_left_ICON {
          width: 240px;
          position: absolute;
          bottom: 8px;
          right: 12px;
          display: flex;
          justify-content: space-between;
          z-index: -1;

          & > li {
            width: 42px;
            height: 42px;
            background: rgba(0, 0, 0, 0.6);
            border-radius: 4px;
            // backdrop-filter: blur(1px);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .sound-effect {
    &-list {
      padding: 10px 0px 80px 0px;

      &-content {
        width: 1200px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 18px;
        grid-auto-flow: row;
      }

      &-pager {
        margin-top: 40px;
      }
    }
  }


  .anchor {
    position: absolute;
    top: -100px;
  }
}

.bg-img {
  width: 100%;
  min-height: 580px;
  max-height: 600px;
  background: url("~@/assets/image/campingPic/campic.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  top: 70px;
  left: 0;

  img {
    width: 100%;
    max-height: 600px;
    visibility: hidden;
  }
}

.active-header-container {
  width: 100%;
  height: 480px;
  position: relative;

  .block {
    img {
      width: 100%;
      visibility: hidden;
    }
  }

  .bg-title-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    padding-top: 73px;

    .page-active-title {
      width: 100%;
      font-size: 36px;
      font-weight: 400;
      color: #004f3d;
      line-height: 48px;
      text-shadow: 0px 2px 1px rgba(25, 75, 59, 0.5);
      text-align: center;

      img {
        width: 533px;
        height: 48px;
      }
    }

    .page-active-sub-title {
      width: 100%;
      height: 24px;
      font-size: 20px;
      font-weight: 400;
      color: #004f3d;
      line-height: 24px;
      letter-spacing: 5px;
      text-align: center;
      margin-top: 36px;
    }
  }

  .page-active-nav {
    width: 1200px;
    height: 180px;
    background: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(33, 48, 83, 0.2);
    margin: 0 auto;
    margin-top: 59px;

    .page-active-nav-wrapper {
      width: 1200px;
      height: 180px;
      padding: 29px 0 15px 0;
      box-sizing: border-box;
      display: flex;

      .nav-item {
        flex: 1;
        cursor: pointer;
        text-align: center;
        font-size: 20px;
        font-family: jiangxizhuokai-Regular, jiangxizhuokai;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.8500);
        line-height: 20px;
        padding-top: 7px;


        img {
          width: 64px;
          height: 64px;
          display: block;
          margin: 0 auto;
          margin-bottom: 24px;
          &.title-img {
            width: auto;
            height: 24px;
            margin: 0 auto;
          }
        }
        p {
          width: 100%;
        }

        & + .nav-item {
          position: relative;
          margin-left: 1px;

          &:before {
            content: '';
            position: absolute;
            left: -1px;
            width: 2px;
            height: 136px;
            top: 0;
            background-color: rgba(0, 0, 0, 0.1000);
          }
        }
      }
    }
  }
}

</style>
